.hero-section-container {
  height: calc(100vh - 90px); // Minus the height of navigation header
  position: relative;
  @include tablet() {
    height: 500px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  &__background-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 1s ease-in-out;
  }
}

.hero-content__container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content__container {
  h5 {
    font-size: 14px;
    letter-spacing: 4.76px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 24px;
  }

  button {
    width: 196px;
  }

  p {
    text-align: right;
  }
}

.hero-content__container > p {
  position: absolute;
  text-align: right;
  bottom: 16px;
  right: 20px;
}

.hero-content,
.hero-content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content {
  max-width: 335px;
  gap: 48px;
}

.hero-content > div {
  gap: 16px;
}

@media screen and (min-width: 768px) {
  .hero-content__container {
    .hero-content {
      max-width: 672px;
      gap: 54px;
      > div {
        gap: 28px;
      }
    }
    h5 {
      font-size: 18px;
      letter-spacing: 6.12px;
    }

    h4 {
      font-size: 36px;
    }

    .cta {
      flex-direction: row;
      width: 100%;
      padding: 0 75px;
      > * {
        width: 100%;
      }
    }

    button {
      width: 100%;
    }

    p {
      font-size: 18px;
      right: 45px;
      bottom: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .hero-content__container {
    .hero-content {
      transform: translateY(52px);
    }
  }
}
