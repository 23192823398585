.download-app-component {
  background-color: $lightGold;
  &__container {
    @include containerWithMargin();
    @include dialogueTitleText();
    padding: 1rem 0;
    @include tablet() {
      padding: 2rem;
    }
    .download-app {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $gray;
      &__divider {
        width: 15rem;
      }
      &__text {
        margin: 1rem;
        width: 60%;
        @include mobile() {
          width: 100%;
          margin-bottom: 3rem;
        }
      }
      &__buttons {
        display: flex;
        @include smallMobile() {
          flex-direction: column;
        }
        &__store {
          margin: 1rem;
          width: 9rem;
          height: auto;
          @include smallMobile() {
            margin: 0.5rem;
          }
        }
      }
    }
  }
}
