.web-service-error-message-component {
  border: 1px solid $red;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  &__message {
    @include normalText();
    color: $red;
  }
}
