:root {
  /* colors */
  --color-brand-dark: #baa57e;
  --color-brand-medium: #e8deca;
  --color-brand-light: #f9f7f3;
  --color-neutral-white: #ffffff;
  --color-neutral-light: #f6f6f6;
  --color-neutral-medium: #b2b2b2;
  --color-neutral-dark: #505050;
  --color-neutral-off-black: #191919;
  --color-form-green: #40ad29;
  --color-form-red: #b71610;

  /* font weights */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-demi-bold: 600;

  /* global variables  */
  --max-width: 1110px;
  --gapSmall: 20px;
  --gapLarge: 30px;
  --header-height: 90px;

  --white: #fff;
  --black: #191919;

  --gold-dark: #baa57e;
  --gold-medium: #e8deca;
  --gold-light: #f9f7f3;

  --grey-dark: #505050;
  --grey-medium: #b2b2b2;
  --grey-light: #f6f6f6;

  --forms-red: #b71610;
  --forms-green: #40ad29;

  --header-z-index: 1299;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: Avenir Next, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

main {
  min-height: 100vh;
}

h1 {
  margin: 0;
  font-weight: var(--font-weight-demi-bold);
  font-size: 48px;
  line-height: 1.2;
}

h2 {
  margin: 0;
  font-weight: var(--font-weight-demi-bold);
  font-size: 38px;
  line-height: 1.2;
}

h3 {
  margin: 0;
  font-weight: var(--font-weight-demi-bold);
  font-size: 28px;
  line-height: 1.2;
}

h4 {
  margin: 0;
  font-weight: var(--font-weight-demi-bold);
  font-size: 24px;
  line-height: 1.2;
}

h5 {
  margin: 0;
  font-weight: var(--font-weight-medium);
  font-size: 18px;
  line-height: 1.2;
}

p {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: 14px;
  line-height: 1.4;
}

a {
  margin: 0;
  color: inherit;
  font-weight: var(--font-weight-regular);
  font-size: 14px;
  line-height: 1.4;
  text-decoration: underline;
}

a:visited {
  color: inherit;
  text-decoration: underline;
}

a:active {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  color: inherit;
  text-decoration: underline;
}

a:focus {
  color: inherit;
  text-decoration: underline;
}

.small {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: 12px;
  line-height: 1.4;
}

.extra-small {
  margin: 0;
  font-weight: var(--font-weight-regular);
  font-size: 11px;
  line-height: 1.4;
}

.demi-bold {
  font-weight: var(--font-weight-demi-bold) !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}

.invisible {
  position: static;
  width: auto;
  height: 0px;
  margin: 0px;
  padding: 0px;
  overflow: visible;
  white-space: normal;
  visibility: hidden;
  clip: auto;
}

.outOfBounds {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: var(--gapLarge);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: 0 var(--gapSmall);
  row-gap: var(--gapLarge);
}

@media screen and (min-width: 1170px) {
  :root {
    --gapLarge: 70px;
  }

  .container {
    margin: 0 auto;
  }
}

.iconGold path {
  fill: var(--color-brand-dark);
}

.iconWhite path {
  fill: var(--color-neutral-white);
}

.iconBlack path {
  fill: var(--color-neutral-off-black);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .hideMobile {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hideTablet {
    display: none;
  }
}
