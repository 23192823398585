.inspirationList {
  &__card {
    cursor: pointer;
  }
  &__card-image {
    margin: 0;
    position: relative;
    background-size: cover;
    display: flex;
    & img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__card-info {
    border-right: 1px solid $brandColor;
    border-left: 1px solid $brandColor;
    border-bottom: 1px solid $brandColor;
    height: 135px;
  }
  &__card-info h4 {
    @include dialogueTitleText();
    margin: 0;
    padding: 1.25rem 0 0;
    color: $gray;
    font-weight: 600;
    text-align: center;
    z-index: 1;
  }
  &__card-info p {
    @include normalText();
    padding: 10px 16px;
    color: $gray;
    font-weight: 400;
    text-align: center;
    z-index: 1;
    line-height: 140%;
  }
}
