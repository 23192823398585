$white: #fff;
$black: #191919;
$gray: #505050;
$lightGray: #b2b2b2;
$lighterGray: #f6f6f6;
$darkGray: #272b2f;
$darkGold: #b9a57e;
$brandColor: #baa57e;
$lightGold: #e8deca;
$lighterGold: #f9f7f3;
$brandColorLight: #d2bca0;
$red: #b71610;
$green: #3fae29;
