.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  &__spinner {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
