.mobile-dialogue {
  z-index: calc(var(--header-z-index) - 1);
}

.mobile-dialogue-container {
  margin-top: 110px;
  padding: 66px 20px 30px 20px;
  .MuiList-root {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mobile-navigation-item {
  text-align: center;
  justify-content: center;
  margin-bottom: 9px;

  .MuiListItemText-primary {
    font-size: 14px;
    font-weight: 400;
  }
  &__icon {
    margin-bottom: 1rem;
  }
  &_join_us_text {
    text-decoration: none;
    color: $gray;
  }
  &-divider {
    max-width: 220px;
    margin: -4px auto 2px;
    .divider_circles {
      margin: 15.5px 10px;
    }
  }
  &-button {
    margin-top: 1.5rem;
    button {
      font-size: 11px;
      font-weight: 600;
      width: 75px;
      height: 25px;
      line-height: 1;
    }
  }
}
