.why-join {
  @include containerWithMargin();
  &__title {
    @include pageMobileTitleText();
    font-weight: 600;
    text-align: center;
    margin: 3rem 0 2rem 0;
    color: $gray;
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5rem;
    margin-bottom: 4rem;
    @include tablet() {
      flex-direction: column;
      gap: 3rem;
    }
    .content-box {
      flex: 1;
      &__title {
        @include footerTitleText();
        color: $gray;
        text-align: center;
        padding-bottom: 0.5rem;
      }
      &__content {
        @include normalText();
        margin-top: 1rem;
        color: $gray;
        text-align: center;
        @include mobile() {
          margin: 1rem 1.5rem 0 1.5rem;
        }
      }
    }
  }
}
