.search-input {
  &__container {
    padding: 1.875rem 16.875rem;
    background: $lightGold;

    @include tablet() {
      padding: 2.5rem 1.25rem;
    }
  }

  &__section {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include tablet() {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }

  &__text-field {
    position: relative;
    height: 45px;
    width: 41.438rem;
    background-color: $white;
    border-radius: 5px;

    @include tablet() {
      width: 100%;
    }
  }

  &__button {
    position: relative;
    border-radius: 5px;
    width: 160px;
    height: 44px;
    left: 10px;

    @include tablet() {
      left: 0px;
    }

    @include tablet() {
      width: 14.938rem;
    }
  }
}