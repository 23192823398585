.compare-membership-background {
  background-color: $white;
}

.compare_membership {
  @include containerWithMarginSpecial();
  scroll-behavior: smooth;

  &__title {
    @include pageMobileTitleText();
    font-weight: 600;
    text-align: center;
    margin: 3rem 0 2rem 0;
    color: $gray;
    overflow-x: auto;

    @include tablet {
      @include dialogueTitleText();
    }
  }

  &__top_text {
    @include normalText();
    margin: 2rem auto;
    color: $gray;
    text-align: center;
    padding: 0 30px;
    max-width: 630px;

    @include tablet {
      margin: 1rem auto;
    }
  }
}

.membership-plans-container {
  margin: 75px auto;
  max-width: 1117px;

  @include desktop() {
    padding: 0 15px;
  }

  @include largeDesktop() {
    max-width: 1386px;
  }

  .wrap-table {
    overflow: hidden;

    & > div {
      margin: 0 -5px;

      @include largeDesktop() {
        margin: 0 -10px;
      }

      @media screen and (max-width: $md-1) {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
      }
    }
  }
}

.membership-plans {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;

  @include desktop() {
    table-layout: fixed;
  }

  @media screen and (max-width: $md-1) {
    table-layout: auto;

    thead th:first-child,
    tbody th:first-child,
    tfoot th:first-child {
      display: none;
    }
  }

  th {
    text-align: left;
    font-weight: inherit;
    scroll-snap-align: start;
  }

  tr {
    border-spacing: 0;
    border-collapse: collapse;
    vertical-align: top;
  }

  td,
  th {
    padding: 0 5px;

    @media screen and (max-width: $md-1) {
      padding: 10px;
    }

    @include largeDesktop() {
      padding: 0 10px;
    }
  }

  .col-container {
    box-sizing: border-box;
    border-left: solid 2px $lightGold;
    border-right: solid 2px $lightGold;
    width: 209px;
    position: relative;

    @include desktop() {
      width: auto;
    }

    @media screen and (max-width: $md-1) {
      border: none !important;
      width: 300px;
    }

    &.col-highlight {
      @media screen and (min-width: $md) {
        //transition: all .2s;
        border-color: $darkGold !important;
        background: #f9f7f3;

        &::before,
        &::after {
          //transition: all .2s;
          background: $darkGold;
        }

        .row-container::after {
          content: '';
          display: block;
          height: 100%;
          width: 100%;
          background: #f9f7f3;
          top: -100%;
          position: absolute;
        }
      }
    }

    img {
      max-width: 100% !important;
      height: auto;
    }
  }

  thead th {
    width: 100%;

    .col-container {
      padding: 10px;
      border-top: solid 2px $lightGold;
      border-radius: 10px 10px 0 0;
      z-index: 1;

      @media screen and (max-width: $md-1) {
        text-align: center;
        padding: 0;
      }
    }
  }

  &-title {
    @include titleText();
    font-weight: 600;
    margin: 20px 0;
    position: sticky;
  }

  &-price {
    @include normalText();
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;

    &-duration {
      @include smallText();
      font-weight: 400;
    }
  }

  &-description {
    @include normalText();
    margin: 30px 0;
  }

  tbody .col-container,
  tfoot .col-container {
    &::before,
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 200%;
      background: $lightGold;
      position: absolute;
      bottom: 10px;

      @media screen and (max-width: $md-1) {
        display: none;
      }
    }

    &::before {
      left: -2px;
    }

    &::after {
      right: -2px;
    }
  }

  th.membership-feature-row {
    text-align: left;
  }

  .membership-feature-row {
    @include normalText();
    position: relative;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    border-bottom: solid 1px #d4d4d4;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #d4d4d4;
      position: absolute;
      bottom: -1px;
      z-index: 1;
    }

    &-title {
      @include normalText();
      font-weight: 600;
    }

    .has-feature {
      @media screen and (max-width: $md-1) {
        color: $darkGold;
        font-weight: 600;
        font-size: 12px;
        max-width: 50%;
      }
    }
  }

  .row-container {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0;
    box-sizing: border-box;

    .color-inactive {
      color: $lightGray;
    }
  }

  .membership-button-cell {
    .col-container {
      padding: 46px 10px 20px;
      border-bottom: solid 2px #e8deca;
      border-radius: 0 0 10px 10px;
      text-align: center;
    }
  }

  .membership-button-become {
    border-radius: 5px;
    height: 44px;
    width: 100%;
  }

  .membership-feature-cell {
    display: flex;
    justify-content: center;
    z-index: 1;

    @media screen and (max-width: $md-1) {
      justify-content: space-between;
      text-align: right;
    }

    &-title {
      @include normalText();
      font-weight: 600;
      text-align: left;
      max-width: 50%;
      display: none;

      @media screen and (max-width: $md-1) {
        display: block;
      }
    }
  }

  .membership-feature-cell-sumary {
    text-align: left;
    margin-top: 10px;
    color: $gray;
    display: none;

    @media screen and (max-width: $md-1) {
      display: block;
    }
  }

  .icon-no-has-feature {
    height: 13px;

    path {
      stroke: $darkGold;
    }
  }
}
