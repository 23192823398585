* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 110px;
}

body {
  color: $darkGray;
  font-size: 16px;
  strong {
    font-weight: 600;
  }
}

.main-content-container {
  min-height: calc(100vh - 421px); // Height of the footer and header
  @include tablet() {
    min-height: calc(100vh - 796px); // Height of the footer and header
  }

  // specific style for content we need to fit the height eg images used as background
  &:has(.main-content-fullheight) {
    height: 100%; 
    position: relative;
    display: flex;
    flex-direction: column;
    > .main-content-fullheight {
      flex-grow: 1;
      display: flex;
    }
  }  
}