.enquire-container {
  margin-top: 50px;

  &__hotel-carousel {
    padding: 0px 0px 0px 30px !important;
  }

  &__hotel-carousel > div:first-child {
    justify-content: start;
  }

  &__hotel-carousel #carousel {
    margin-top: 50px;
    ul {
      gap: 10px;
      li {
        justify-content: start;
        width: 100%;
      }
      li > div {
        width: 300px;
        height: 195px;
      }
    }
  }

  &__explore {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    &-button {
      width: 100%;
      max-width: 200px;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 519px;
    flex-shrink: 0;
    background: url('../assets/myler-enquire-background-cover.png') no-repeat;
    background-position: -451px;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  &__message-card {
    text-align: center;
    position: absolute;
    background-color: $white;
    padding: 40px 10px;
    border-radius: 10px;
    margin: 35% 20px 0px 20px;
    
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div > p {
      max-width: 296px;
    }
  }

  .btn {
    width: 100%;
    max-width: 200px;
  }
}

@media screen and (min-width: 650px) {
  .enquire-container {
    order: 2;
    margin-top: 90px;
    &__image-container {
      background-position: 0px;
    }

    &__hotel-carousel {
      padding: 0 30px !important;
    }
    &__hotel-carousel > div:first-child {
      justify-content: center;
    }
    &__hotel-carousel #carousel {
      margin-top: 60px;
      ul {
        gap: 30px;
        li {
          justify-content: center;
          width: calc(100vw - 77px);
        }
        li > div {
          width: 250px;
          height: 150px;
        }
      }
    }

    &__message-card {
      margin-top: 150px;
      max-width: 480px;
      padding: 40px 40px;
      
      div > p {
        max-width: 100%;
      }

    }
  }
}
