.inspiration {

  &__title {
    margin-bottom: 1.313rem;
  }

  &__title h2 {
    @include pageMobileTitleText();
    color: $black;
    font-weight: 600;
  }

  &__outerWrapper {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    @include smallMobile {
      grid-template-columns: auto;
    }
  }
}

.view_all_text_button {
  background-color: transparent;
  border: 1px solid #505050;
  border-radius: 5px;
  color: #505050;
  font-weight: 600;
}