@mixin button {
  border-radius: 5px;
  @include normalText();
  font-weight: bold;
  height: 44px;
  cursor: pointer;
  border: none;
  transition: background-color 1s;
}

.button-body-filled {
  @include button();
  background-color: $brandColor;
  color: $black;
  &:hover {
    background-color: $brandColorLight;
  }
  &:focus {
    outline: none;
  }
}

.button-body-filled-dark {
  @include button();
  background-color: $black;
  color: $white;
  height: 2rem;
  width: 6rem;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
}

.button-body-outlined-dark {
  @include button();
  background-color: transparent;
  border: 1px solid $black;
  color: $black;
  height: 2rem;
  width: 6rem;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
}

.button-body-link {
  @include normalText();
  height: 20px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  &:focus {
    outline: none;
  }
}
