.hotels-container {
  margin-top: 50px;

  &__hotel-carousel {
    padding: 0px 0px 0px 20px !important;
  }

  &__hotel-carousel > div:first-child {
    justify-content: start;
  }

  &__hotel-carousel #carousel {
    margin-top: 50px;
    ul {
      gap: 10px;
      li {
        justify-content: start;
        width: 100%;
      }
    }
  }

  &__explore {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    &-button {
      width: 100%;
      max-width: 200px;
    }
  }

  .linkButton {
    height: 45px;
    width: 250px;
    padding: 12px 100px;
    border-radius: 5px;
    background-color: $darkGold;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
  }
}

@media screen and (min-width: 650px) {
  .hotels-container {
    margin-top: 90px;

    &__hotel-carousel {
      padding: 0 30px !important;
    }
    &__hotel-carousel > div:first-child {
      justify-content: center;
    }
    &__hotel-carousel #carousel {
      margin-top: 60px;
      ul {
        gap: 30px;
        li {
          justify-content: center;
          width: calc(100vw - 77px);
        }
        li > div {
          width: 250px;
          height: 150px;
        }
      }
    }
    &__explore {
      margin-top: 60px;
    }
  }
}
