.select-container {
  min-width: 80px;
  &__label {
    @include normalText();
    color: $black;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__button-icon {
    margin-left: 15px;
  }
  span svg {
    width: 12px;
    height: 6px;
  }
  &__button {
    background-color: $white;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    cursor: pointer;
    border: 1px solid $lightGray;
    border-radius: 5px;
    padding: 0.5rem;
    &.opened {
      border-color: $gray;
      border-radius: 5px 5px 0 0;
    }
    .select-button-text {
      @include normalText();
      &.uppercase-value {
        text-transform: uppercase;
      }
    }
  }
  button {
    text-transform: none;
  }
}
.currency-layout {
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  color: black;
  font-weight: normal;
  text-transform: none;
  padding-left: 0;
  text-decoration: underline;
  .select-container--open & {
    background-color: transparent;
    text-decoration: none;
  }
  justify-content: flex-start;
}
.form-layout {
  &:hover {
    background-color: transparent;
    text-decoration: none;
    border-color: black;
  }
  display: flex;
  color: currentColor;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #b2b2b2;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  padding: 8px 15px;
  max-height: 45px;
  text-decoration: auto;
  transition: none;
}
