.header-component {
  background-color: $black;

  width: 100%;
  z-index: var(--header-z-index);
  position: sticky;
  top: 0;

  &__container {
    display: flex;
    flex-direction: row;
    height: 90px;
    position: relative;
    @include tablet() {
      height: 110px;
    }
    @include containerWithMargin();
    .header-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      justify-content: flex-start;
      text-decoration: none;
      @include tablet() {
        display: none;
      }
    }
    .burger-icon {
      display: none;
      @include tablet() {
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .header-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.2rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .header-rigth {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      justify-content: flex-end;
      @include tablet() {
        display: none;
      }
    }
    .header-navigation-item {
      @include normalText();
      color: $white;
      text-decoration: none;
      &:hover {
        color: $brandColor;
        transition: color 1s;
      }
    }
    .header-join_us_text_button {
      color: $brandColor;
      text-decoration: none;
    }
  }
}
