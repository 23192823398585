.career-title {
  @include titleText();
  color: $black;
  font-weight: bold;
  margin: 1rem 0;
}

.career-description-title {
  @include normalText();
  color: $gray;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.career-description {
  @include normalText();
  color: $gray;
  margin-bottom: 1.5rem;
}

.career-requirement-list {
  padding-inline-start: 1rem;
  &__element {
    @include normalText();
    color: $gray;
    margin: 0.2rem 0;
    
  } 
}

.accordion-container{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  @include tablet() {
    flex-direction: column;
  }
  &__left{
    flex: 2;
  }
  &__right{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem 0.5rem;
    border: 1px solid $brandColor;    
  }
}

.career-instruction-text {
  @include normalText();
  color: $gray;
  margin-left: 1rem;
  &__link {
    color: $gray;
  }
}
