.press-highlights-section {
  &__title {
    @include pageTitleText();
    font-weight: bold;
    color: $black;
    text-align: center;
    margin: 3rem 0;
  }
  &__list {
    @include containerWithMargin();
    display: grid;
    row-gap: 3rem;
    column-gap: 2rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "article-1 article-1 article-1 article-1 article-1 article-1"
      "article-2 article-2 article-2 article-3 article-3 article-3"
      "article-4 article-4 article-5 article-5 article-6 article-6";
    @include tablet() {
      margin: 0;
      grid-template-columns: 1fr;
      row-gap: 2rem;
      grid-template-areas:
        "article-1"
        "article-2"
        "article-3"
        "article-4"
        "article-5"
        "article-6";
    }
    .article-1 {
      grid-area: article-1;
    }
    .article-2 {
      grid-area: article-2;
    }
    .article-3 {
      grid-area: article-3;
    }
    .article-4 {
      grid-area: article-4;
    }
    .article-5{
      grid-area: article-5;
    }
    .article-6 {
      grid-area: article-6;
    }
    .press-list-element {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      @include tablet() {
        @include containerWithMargin();
      }
      text-decoration: none;
      &:first-child {
        margin: 0;
        flex-direction: row;
        background-color: $lightGold;
        gap: 2rem;
        .press-list-element__image {
          flex: 1;
        }
        .press-list-element__text-content {
          flex: 1;
          position: relative;
          padding: 2rem 0;
          @include tablet() {
            @include containerWithPadding();
            gap: 2rem;
          }
          .article-title {
            display: none;
          }
          .news-media-title {
            display: block;
          }
          .article-description {
            padding-right: 2rem;
            padding-top: 1rem;
            max-width: 85%;
            @include footerTitleText();
            @include tablet() {
              max-width: 100%;
            }
          }
        }
        @include tablet() {
          flex-direction: column;
          gap: 2rem;
        }
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        @include tablet() {
          flex-direction: row;
          gap: 1rem;
          padding-bottom: 1.5rem;
          border-bottom: 1px solid $lightGray;
          .press-list-element__image {
            flex: 1;
          }
          .press-list-element__text-content {
            flex: 2;
            .article-title {
              margin-top: 0;
              @include mobile() {
                @include smallText();
              }
            }
            .article-description {
              overflow: hidden;
              @include mobile() {
                @include hotelCardDescText();
                max-height: 28px;
              }
            }
            .article-date {
              @include smallText();
            }
          }
        }
      }
      &:nth-child(6) {
        @include tablet() {
          border-bottom: none;
        }
      }
      &__image {
        height: auto;
        flex: 1;
      }
      &__text-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .article-title {
          @include normalText();
          color: #000;
          font-weight: bold;
          margin: 1rem 0 0.5rem 0;
        }
        .article-description {
          @include normalText();
          color: $black;
        }
        .news-media-title {
          @include normalText();
          font-weight: bold;
          color: $gray;
          display: none;
        }
        .article-date {
          @include hotelCardDescText();
          color: $gray;
          margin: 0.5rem 0;
        }
      }
    }
  }
  &__bottom {
    text-align: center;
    @include containerWithMargin();
    .more-articles-button {
      width: 33.3%;
      height: 3rem;
      margin: 2rem;
      color: $gray;
      border-color: $gray;
      margin-top: 3rem;
      @include mobile() {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 4rem;
        margin-top: 2rem;
      }
    }
  }
}
