.partners-container {
  @include containerWithMargin();
  margin-top: 3rem;
  margin-bottom: 6rem;
  &__title {
    @include dialogueTitleText();
    text-align: center;
    margin: 2rem;
    font-weight: bold;
    @include mobile() {
      display: none;
    }
  }
  &__icons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    margin-bottom: 3rem;
    @include tablet() {
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 4rem;
      margin-bottom: 6rem;
    }
    @include mobile() {
      grid-template-columns: 1fr 1fr;
      margin-top: 2rem;
      margin-bottom: 4rem;
      column-gap: 1rem;
      row-gap: 1rem;
    }
    .icon {
      border-radius: 10px;
      border: 1px solid $brandColor;
      padding-bottom: 60%;
      position: relative;
      &__container {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        padding: 15%;
        .partner-icon {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
        }
      }
    }
  }
}
