.global-presence {
  background-image: url("./assets/background.jpeg");
  &-up-component {
    background-color: $lightGold;
    &__container {
      @include containerWithMargin();
      @include dialogueTitleText();
      padding: 1rem 0;
      .top-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $gray;
        &__divider {
          width: 15rem;
        }
        &__text {
          margin: 1rem;
          width: 60%;
          font-weight: 600;
          max-width: 35%;
          @include tablet() {
            max-width: 75%;
          }
          @include mobile() {
            width: 100%;
          }
        }
        &__button {
          align-items: center;
          justify-content: center;
          text-align: center;
          margin: 1rem;
          @include tablet() {
            margin-top: 1rem;
            width: 100%;
          }
          .view-membership__button {
            height: 3rem;
            width: auto;
            padding: 0 2rem;
            background-color: transparent;
            @include tablet() {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &-bottom-component {
    &__container {
      @include containerWithMargin();
      @include dialogueTitleText();
      .bottom-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 5rem;
        @include mobile() {
          margin-top: 2rem;
        }
        &__image {
          width: 100%;
        }
        &__button {
          align-items: center;
          justify-content: center;
          text-align: center;
          margin: 2rem;
          color: $black;
          @include tablet() {
            margin-top: 1rem;
            width: 100%;
            color: $gray;
          }
          .view-membership__button {
            height: 3rem;
            width: auto;
            padding: 0 2rem;
            background-color: transparent;
            @include tablet() {
              border: 1px solid $gray;
              color: $gray;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
