@import './components/CareerAccordion';
@import './components/CareerForm';

.career-list-container {
  @include containerWithMargin();
  &__title {
    @include pageMobileTitleText();
    color: $gray;
    font-weight: bold;
    text-align: center;
    margin: 3rem 0 2rem 0;
  }
  &__list {
    margin-bottom: 3rem;
  }
}
