.press-list-page {
  @include containerWithMargin();
  &__container {
    margin-top: 3rem;
    margin-bottom: 2rem;
    @include mobile() {
      margin-top: 2rem;
    }
    .title {
      @include pageTitleText();
      font-weight: 600;
      text-align: center;
      margin-bottom: 3rem;
      @include mobile() {
        margin-bottom: 2rem;
      }
    }
    .articles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
      row-gap: 2rem;
      @include tablet() {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mobile() {
        grid-template-columns: repeat(1, 1fr);
      }
      .article-card {
        cursor: pointer;
        text-decoration: none;
        color: #272b2f;
        @include mobile() {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1rem;
          column-gap: 1rem;
          align-items: center;
          padding-bottom: 1rem;
          border-bottom: 1px solid $lightGray;
        }
        .article-details {
          .article-title {
            @include hotelCardDescText();
            font-weight: 600;
            margin-top: 1rem;
            @include mobile() {
              margin-top: 0;
            }
          }
          .article-content {
            @include normalText();
            color: $black;
            margin-top: 1rem;
            margin-bottom: 1rem;
            @include mobile() {
              display: flex;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          .article-date {
            @include smallText();
            color: $gray;
          }
        }
      }
      .article-card:last-child {
        border: none;
      }
    }
    .press-list-button {
      display: flex;
      justify-content: center;
      width: 100%;
      .load-more-button {
        width: 33.3%;
        height: 3rem;
        margin: 2rem;
        color: $gray;
        border-color: $gray;
        margin-top: 3rem;
        @include mobile() {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 4rem;
          margin-top: 2rem;
        }
      }
    }
  }
}
