.accordion-title {
  @include footerTitleText();
  color: $gray;
}
.accordion-content {
  @include normalText();
  color: $black;
  margin-left: 1.2rem;
  @include tablet() {
    display: inline;
    margin-left: 0;
  }
}
.accordion-list-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

