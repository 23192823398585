@import './components/Navigation';
@import './components/About';
@import './components/Why';
@import './components/Resources';
@import './components/Enquire';
@import './components/Hotels';

.enquire-testimonial {
  display: flex;
  flex-direction: column;
}

.myler-section {
  &-container {
    position: relative;
  }
  &-overlay {
    position: absolute;
    width: 100%;
    color: white;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  &-overlay {
    margin-top: 50px;
  }
  &-mainHeading {
    max-width: auto;
    text-align: center;
    font-size: 24px !important;
  }
  &-subHeading {
    max-width: 252px;
    text-align: center;
    font-size: 18px !important;
  }
}

@media screen and (min-width: $sm) {
  .enquire-testimonial {
    flex-direction: column;
  }
  .myler-section {
    &-coverContainer * {
      object-fit: cover !important;
      height: 520px !important;
      width: 100% !important;
    }

    &-overlay {
      margin-top: 115px;
      gap: 28px;
    }
    &-mainHeading {
      font-size: 36px !important;
    }
    &-subHeading {
      font-size: 32px !important;
      max-width: 100%;
    }
  }
}
