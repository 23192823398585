.footer-component {
  background-color: $black;
  padding: 2.9rem 0 2rem 0;
  &__container {
    @include normalText();
    max-width: 1178px;
    margin: 0 auto;
    padding: 0 34px;
    color: $white;
    .footer-top {
      display: flex;
      justify-content: space-between;
      @include tablet() {
        flex-direction: column;
      }
      &__item {
        @include tablet() {
          margin-top: 2rem;
        }
        &__phone {
          margin-top: 1rem;
          color: $lightGray;
          .footer-phone-number {
            color: inherit;
            text-decoration: none;
            font-size: 0.6875rem; // 11px
          }
        }
        &__link {
          display: block;
          color: $lightGray;
          text-decoration: none;
          margin-top: 1rem;
          transition: color 0.1s ease;
          font-size: 0.6875rem; // 11px
        }
        &__link:hover {
          color: $white;
        }
      }
    }
    .footer-double {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 34px;
      row-gap: 0;
      width: fit-content;
    }
    .footer-bottom {
      font-size: 11px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 4rem;
      @include tablet() {
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 3rem;
      }
      &__item {
        display: flex;
        align-items: center;
        @include tablet() {
          margin-top: 1rem;
        }
        &__company {
          @include tablet() {
            order: 1;
          }
        }
        &__socials {
          @include tablet() {
            order: 3;
          }
        }
        &__links {
          @include tablet() {
            order: 2;
          }
        }
        &__company-logo {
          margin-right: 1rem;
          @include tablet() {
            display: none;
          }
        }
        &__company-name {
          color: $lightGray;
        }
        &__link {
          transition: color 0.1s ease;
          font-size: 11px;
          margin-left: 1rem;
          text-decoration: none;
          color: $lightGray;
        }
        &__link:hover {
          color: $white;
        }
        &__whatsapp {
          position: fixed;
          bottom: 1.5rem;
          right: 1.5rem;
          z-index: 100;
          @include tablet() {
            right: 1rem;
          }
        }
      }
    }
    .footer-engage {
      display: flex;
      border-bottom: 1px solid #4e4e4e;
      margin-bottom: 2.5rem;
      height: 4.25rem;
      @include tablet() {
        flex-direction: column;
        border-bottom: none;
        height: auto;
      }
      @include mobile() {
        align-items: center;
        margin-bottom: 0;
      }
      &__newsletter {
        height: 100%;
        flex: 1 1 auto;
        @include tablet() {
          margin-bottom: 2.5rem;
        }
        @include mobile() {
          width: 100%;
        }
      }
      &__social {
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 7.5rem;
        &__logo {
          display: block;
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
