.short-info {
  &__logo {
    &__infoContainer {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-content: center;
      margin-top: 30px;
      padding: 20px;
      background-color: $lighterGray;
      border-radius: 10px;

      @media screen and (max-width: 422px) {
        width: 100%;
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      margin: 10px;
    }

    &__box {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      padding: 10px;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      width: 80px !important;
      height: 80px !important;
      object-fit: contain !important;
    }

    &__infoText {
      margin: auto 30px auto 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: $gray;

      &__container {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
  }

  &__container {
    width: fit-content;
    margin: 30px 0px;
    padding: 20px;
    background-color: $lighterGray;
    border-radius: 10px;

    @media screen and (max-width: 422px) {
      width: 100%;
    }
  }

  &__infoText {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: 0;
      text-align: end;
    }
  }


}