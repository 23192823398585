.why-little-emperors {
  &__top {
    display: flex;
    justify-content: center;
    padding: 87px 0;
    @include desktop() {
      padding: 77px 0;
    }
    @include tablet() {
      padding: 50px 20px 52px;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 78px;
    width: 100%;
    max-width: 1140px;
    @include largeDesktop() {
      max-width: 1351px;
      gap: 126px;
    } 
    @include desktop() {
      gap: 57px;
    }
    @include tablet() {
      gap: 30px;
      flex-direction: column;
      max-width: 100%;
    }
    @include mobile() {
      gap: 25px;
      flex-direction: column;
      max-width: 100%;
    }

    &__logo {
      flex: 0 0 auto;
      padding-top: 3px;
      svg {
        width: 611px;
        @include largeDesktop() {
          width: 700px;
        } 
        @include desktop() {
          width: 438px;
          margin: auto;
        }
        @include tablet() {
          width: auto;
        }      
      }
    }
    &__text {
      flex: 0 1 auto;
      padding-left: 2px;
      width: 448px;
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        color: $gray;
      }
      @include tablet() {
        text-align: center;
        width: 420px;
      }
      @include mobile() {
        width: 100%;
        padding: 0 9px;
        p {
          font-size: 14px;
          line-height: 19.6px;
        }
      }
    }
  }

  .why-little-emperors__benefits {
    width: 100%;
    display: flex;
    gap: 30px;
    @include tabletSmall() {
      flex-direction: column;
      padding: 0 20px;
      margin-bottom: 30px;
    }
    &__item {
      position: relative;
      flex: 1 1 0;
      min-width: 0;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__text {
      padding: 32px 0 90px;
      text-align: center;
      max-width: 300px;
      @include tablet() {
        padding: 26px 20px 90px;
      }
      @include tabletSmall() {
        padding: 20px 20px 0;
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 32px;
      color: $black;
      @include tablet() {
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: $black;
    }
    &__img { 
      position: relative;
      width: 100%;
      // between desktop(1280) and largeDesktop (1650)
      aspect-ratio: 1.1;
       // after 1650px
      @include largeDesktop() {
        aspect-ratio: 1.44;
      }
      // up to 1280px
      @include desktop() {
        aspect-ratio: 1.25;
      }
      // up to 1024px
      @include tablet() {
        aspect-ratio: 1.25;
      }
      // up to 450px
      @include mobile() {
        aspect-ratio: 1.42;
      }
    }
  }
}
