.privacy-policy-page {
  @include containerWithMargin();
  &__title {
    @include pageMobileTitleText();
    @include mobile() {
      text-align: left;
      margin-top: 3.75rem;
      margin-bottom: 3.125rem;
    }
    text-align: center;
    margin-top: 6.25rem;
    margin-bottom: 4.375rem;
    color: $black;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__content {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5.625rem;
    line-height: 140%;
  }
}