.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__line {
    flex: 1;
    height: 1px;
  }
  &__circles {
    margin: 1rem;
  }
}
