.nav-section {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  &__container {
    display: flex;
    column-gap: 30px;
  }

  &__title {
    position: relative;
    cursor: pointer;
    padding-bottom: 5px;
  }
  &__divider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  &__divider:hover {
    display: block;
    border-bottom: 1px solid black;
  }
}

@media screen and (min-width: $md) {
  .nav-section {
    margin-top: 70px;

    &__container {
      column-gap: 50px;
    }
  }
}
