.top-pick-card-container {
  position: relative;
  cursor: pointer;
  scroll-snap-align: start;
  flex: 0 0 80vw;
  @include mobile() {
    flex: 0 0 90vw;
  }
  &__imageWrapper {
    position: relative;
    height: 35.063rem;
    @include mobile() {
      height: 14.375rem;
    }
  }

  &__gradientLayer {
    position: absolute;
    bottom: 1.938rem;
    left: 4.375rem;
    width: 30.438rem;
    height: 8.438rem;
    background-color: rgba($black, 0.7);
    padding: 0.938rem 1.875rem;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @include mobile() {
      bottom: 1.25rem;
      left: 40px;
      width: 15.75rem;
      height: 4.875rem;
      padding: 0.625rem;
      border-radius: 0.625rem;
    }
    @include tablet() {
      left: 30px;
      width: auto;
      height: auto;
      min-height: 8.438rem;
      min-width: 80%;
    }
  }

  &__description-text {
    @include titleText();
    color: $white;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.625rem;

    @include mobile() {
      @include normalText();
      margin-bottom: 0.313rem;
    }
  }

  &__divider {
    width: 100%;
    border-bottom: 0.063rem solid $brandColor;
  }

  &__location-text {
    @include footerTitleText();
    color: $lightGray;
    font-weight: 500;
    margin-top: 0.625rem;

    @include mobile() {
      @include smallText();
      margin-top: 0.313rem;
    }
  }
}
