.privacy-policy-list {
  text-align: left;
  &_title {
    @include footerTitleText();
    color: $black;
    margin-bottom: 0;
    margin-top: 1.875rem;
  }
  &_item {
    @include normalText();
    color: $gray;
    font-weight: 400;
    margin-left: 5px;
  }
  &_item ol {
    padding-left: 1.25rem;
  }
  &_description {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  } 
}