.career-form-container {
  width: 90%;
  align-items: center;
  margin: 0 auto;
}

.form-title {
  @include dialogueTitleText();
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: $black;
}

.form-success-message {
  @include normalText();
  color: $black;
}

.form-submit {
  margin-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  &_button {
    width: 100%;
  }
}
