.faq-page-container {
  &__title {
    @include pageTitleText();
    font-weight: 600;
    color: $gray;
    margin: 5rem 0 2rem 0;
    @include tablet() {
      @include pageMobileTitleText();
    }
  }
  @include containerWithMargin();
  margin-bottom: 4rem;
  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include tablet() {
      display: inline;
      flex-direction: column;
    }
    .accordion {
      width: 100%;
    }
    .questionbox {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 0.5rem;
      border: 1px solid $brandColor;
      margin-bottom: 3rem;
      @include tablet() {
        margin-left: 0;
        margin-top: 2rem;
      }
      &__title {
        @include footerTitleText();
        text-align: center;
        color: $black;
      }
      &__content {
        @include normalText();
        text-align: center;
      }
    }
  }
}
