.why-myler-section {
  text-align: left;
  margin-top: 50px;

  &__cover {
    display: flex;
  }
  &__cover * {
    height: 480px !important;
    object-fit: cover !important;
    width: 100% !important;
  }

  &__title {
    margin-top: 50px;
    text-align: center;
  }
  &__subtitle {
    padding-top: 36px;
    max-width: 335px;
    margin: 0 auto;
    text-align: center;
  }

  &__enquire {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    &-button {
      width: 100%;
      max-width: 200px;
    }
  }
}

.myler-tools {
  margin-top: 40px;
  background-color: $lighterGold;
  padding: 40px 20px;

  &__container {
    @include containerWithMargin();
  }

  &__tool {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &-title {
      display: flex;
      width: 100%;
      gap: 50px;
      align-items: center;
      text-align: left;
    }

    &-description {
      max-width: 500px;
      text-align: left;
    }
  }

  &__tool:not(:first-child) {
    margin-top: 30px;
  }
}

@media screen and (min-width: $md) {
  .why-myler-section {
    margin-top: 90px;
    text-align: center;

    &__title {
      margin-top: 90px;
    }

    &__subtitle {
      max-width: 570px;
      margin: 0 auto;
    }

    &__enquire {
      margin-top: 90px;
      &-button {
        max-width: 250px;
      }
    }
  }
  .myler-tools {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 90px 60px;

    &__container {
      max-width: 950px;
    }

    &__tool {
      flex-direction: row;
      gap: 50px;

      &-title {
        width: 420px;
      }
      &-icon {
        stroke-width: 2px;
        width: 54px;
      }
    }

    &__tool:not(:first-child) {
      margin-top: 70px;
    }
  }
}
