.newsletter-subscription-footer {
  display: flex;
  gap: 1.25rem;
  @include tablet() {
    flex-direction: column;
  } 
  &__title {
    font-size: 1.125rem; 
    font-weight: 500;     
    white-space: nowrap;
    color: #fff;
    margin: 0;
    padding-top: 0.2rem;
  }
  &__tfContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
   .MuiFormHelperText-root {
    // prevents jump when helper or error text appears on MUI TextField
    @include tablet() {
      position: absolute;
      bottom: -1.5rem;
    } 
   }
  /* passed to the MUI TextField */
  &__textfield {
    width:22.81rem;  
    border-bottom: 1px solid #fff;
    border-radius: 0;
    padding: 0 0.3rem 0.3rem 0;
    @include mobile() {
      width: 100%;
    }
    input {
      background-color: transparent;
      color: white;
      font-weight: 400;
      font-size: 0.875rem;
      padding: 0 0 3px;      
    }   
  }
  /* the wrapper around the MUI TextField */
  .text-field-container {
    display: flex;
    gap: 1.25rem;
    align-items: center;
    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: white
      }
    }
  }
}
