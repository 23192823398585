.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  &__label {
    @include normalText();
    color: $black;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  &__helper-text {
    margin-top: 0.5rem;
    display: block;
    color: $red;
  }
}
