.news-letter-subscription-dialogue-container {
  background-color: #133942;
  background-image: url('./assets/newsletter-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.75rem;
  &__top-actions {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    padding: 0.3rem;
    & path {
      stroke: $white;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8.375rem;
    @include tablet() {
      flex-direction: column;
      gap: 1rem;
    }
    .newsletter-title {
      @include dialogueTitleText();
      color: $white;
      font-weight: bold;
    }
    .newsletter-description {
      @include normalText();
      color: $white;
    }
    .newsletter-success-title {
      @include dialogueTitleText();
      color: $white;
      font-weight: bold;
      text-align: center;
    }
    .newsletter-success-description {
      @include normalText();
      color: $white;
      text-align: center;
    }
    .newsletter-inputs {
      display: flex;
      flex-direction: row;

      &__text-field {
        height: 44px;
        width: 20vw;
        background-color: $white;
        border-radius: 5px;
        @include tablet() {
          width: auto;
        }
      }
      &__button {
        position: relative;
        border-radius: 0 5px 5px 0;
        left: -5px;
        min-width: 125px;
      }
    }
  }
}
