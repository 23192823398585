.faq-title {
  @include footerTitleText();
  color: $gray;
  margin: 1rem 0;
}

.accordion-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  @include tablet() {
    flex-direction: column;
  }
  &__left {
    flex: 2;
  }
}

.accordion-content {
  margin-left: 0 !important;
  font-weight: 400;
}
