.myler-about-section {
  @include containerWithMargin();
  padding-top: 50px;
  text-align: center;

  &__subtitle {
    margin-top: 30px;
    max-width: 335px;
    margin: 20px auto auto;
  }
  &__subtitle h2 {
    font-size: 18px;
    font-weight: 500;
  }
 

  &__body {
    max-width: 625px;
    margin: 30px auto;

    &__text:last-child {
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: $md) {
  .myler-about-section {
    padding-top: 70px;

    &__subtitle {
      max-width: 550px;
    }
  }
}
