.content-checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 0.625rem;
  &__icon {
    display: inline;
    width: 20px;
    margin: 0 0.25rem;
  }
}

.content-checkbox-label-underlined {
  text-align: left;
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.625rem;
  margin: 0 0.15rem;
}

.country-code-selection-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  &__flag {
    @include dialogueTitleText();
  }
  &__dial-code {
    text-align: right;
  }
  &__name {
    flex: 1;
  }
}

.searchable-field {
  position: relative;
  input {
    background-color: white;
    padding: 0 15px;
    height: 45px;
    &:focus {
      // TODO: temp removing border
      // border: 1px solid hsl(0, 0%, 31%) !important;
      border-radius: 5px;
      padding: 0 15px;
    }
  }
  &__suggest-list {
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: white;
    ul {
      list-style-type: none;
    }
    border-top: 0px;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;
    border-radius: 0px 0px 5px 5px;
    li {
      padding: 12px;
    }
    li:hover {
      background: $gray;
      color: $white;
    }
  }
}
