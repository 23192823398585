.hotel-card-container {
  position: relative;
  &__gradient-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000000 11.94%, rgba(0, 0, 0, 0) 38.56%)
  }
  &__description-text {
    @include hotelCardDescText();
    color: $white;
    font-weight: bold;
    position: absolute; 
    bottom: 1rem;
    left: 1rem;
  }
}
