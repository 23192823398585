// Media query mixins
@mixin smallMobile {
  @media screen and (max-width: $xs) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $sm) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $md) {
    @content;
  }
}
@mixin tabletSmall {
  @media screen and (max-width: $md-2) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: $lg) {
    @content;
  }
}

@mixin largeDesktop {
  @media screen and (min-width: $xl) {
    @content;
  }
}

@mixin containerWithMargin {
  max-width: $lg;
  margin: 0 auto;

  @include desktop() {
    margin: 0 9.6%;
  }

  @include tablet() {
    margin: 0 5%;
  }

  @include mobile() {
    margin: 0 20px;
  }
}

@mixin containerWithMarginSpecial {
  margin: 0 auto;

  @include desktop() {
    margin: 0;
  }

  @include tablet() {
    margin: 0;
  }

  @include mobile() {
    margin: 0;
  }
}

@mixin containerWithPadding {
  @include desktop() {
    padding: 0 9.6%;
  }

  @include tablet() {
    padding: 0 5%;
  }

  @include mobile() {
    padding: 0 20px;
  }
}

@function pxRem($px) {
  $base-font-size: 16;
  @return #{$px / $base-font-size}rem;
}
