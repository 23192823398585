.terms-and-conditions-page {
  @include containerWithMargin();
  @include normalText();
  color: $gray;
  max-width: $lg;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: reverse;
  align-items: flex-start;
  @include tablet() {
    padding-top: 1rem;
  }
  &__container {
    max-width: 70%;
    @include tablet() {
      max-width: 100%;
    }
    .title {
      @include pageTitleText();
    }
    .subtitle {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .accordion {
      margin-top: 2rem;
    }
  }
  .terms-spacer {
    margin-left: 2rem;
    padding: 2rem 0.5rem;
    margin-bottom: 3rem;
    width: 100%;
    @include tablet() {
      display: none;
    }
  }
}
