.congratulations-box {
  @include footerTitleText();
  color: $gray;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
}

.registration-success-description-box {
  @include normalText();
  color: $black;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;

  &-link {
    color: $darkGold;
    text-decoration: none;
    font-weight: 600;
  }
}