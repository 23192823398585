// Breakpoints
$xl: 1650px;
$lg: 1280px;
$md: 1024px;
$md-1: 1023px;
$md-2: 767px;
$sm: 450px;
$xs: 375px;

// Margin
$margin: 1rem;
$padding: 30px;
