.press-say-about-us {
  @include containerWithMargin();
  &__title {
    @include dialogueTitleText();
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 3rem 0;
  }
  &__slider {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 70%;
    @include tablet() {
      width: 100%;
      max-height: 100%;
    }
    .slider-container {
      width: 80%;
      margin: auto;
      min-height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__text {
        @include footerTitleText();
        color: $gray;
        font-weight: 500;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include tablet() {
          width: 100%;
        }
      }
    }
    .press_icons {
      &_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .press_icon {
          flex: 1;
        }
      }
      @include tablet() {
        margin: 0;
        display: inline-flex;
        align-items: center;
        .press_icon {
          opacity: 1;
          margin: 2rem;
          display: inline;
        }
        .navigation-arrow {
          padding: 2rem 2rem;
        }
      }
    }
  }
  .divider {
    width: 15rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
}
