.press-detail {
  &__news-media-title {
    @include articleTitleText();
    background-color: $lightGold;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  &__title {
    margin-top: 3rem;
    @include articleTitleText();
    text-align: center;
  }
  &__date {
    @include normalText();
    text-align: center;
    color: $gray;
  }
  &__content-container {
    @include containerWithMargin();
    padding: 0 9.6%;
    @include containerWithPadding();
    @include normalText();
    color: $gray;
    .content-box {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
