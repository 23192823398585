.scanned-media-page {
  .article-image {
    @include articleTitleText();
      background-color: $lightGold;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
  }
  &__container {
    @include containerWithMargin();
    @include mobile() {
      margin-bottom: 4rem;
    }
    .article-title {
      text-align: center;
      .title {
        @include pageTitleText();
        margin: 2rem 0 1rem 0;
      }
      .date {
        @include normalText();
        margin-bottom: 3rem;
      }
    }
    .article-pdf {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
      &__frame {
        width: 100vw;
        max-height: 100vh;
        height: 141vw;
      }
      @include mobile() {
        margin: 1rem 0 2rem 0;
      }
    }
  }
}
