.the-team-page {
  @include containerWithMargin();
  margin-bottom: 4rem;
  &__container {
    .the-team-title {
      @include pageTitleText();
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .team-members {
      display: flex;
      justify-content: center;
      &__grid {
        display: grid;;
        grid-template-columns: repeat(3, minmax(100px, 350px));
        column-gap: 1.5rem;
        row-gap: 1.5rem;
        margin-bottom: 2rem;
        @include tablet() {
          grid-template-columns: repeat(2, minmax(100px, 350px));
          margin-bottom: 3rem;
        }
        @include mobile() {
          grid-template-columns: repeat(1, minmax(100px, 350px));
        }
        .team-member-card {
          border: 1px solid $brandColor;
          max-width: 350px;
          .card-details {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 0;
            .member-name {
              @include footerTitleText();
            }
            .card-divider {
              width: 7rem;
            }
            .member-role {
              @include smallText();
              color: $gray;
            }
          }
        }
      }
    }
  }
}
