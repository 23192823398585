.membership-hero {
  display: flex;
  justify-content: center;
  height: 569px;

  &__image {
    // @include containerWithMarginSpecial();
    // display: flex;
    position: absolute;
    z-index: -1;
    width: 100%;
    // max-width: 1280px;
    // height: 569px;
    // justify-content: center;
    // overflow: hidden;
    // object-fit: cover;


    & img {
      // margin-top: -186px;
      width: 100%;
      height: 569px;
      object-fit: cover;
    }
  }

  &__container {
    display: grid;
    width: 100%;
    max-width: 776px;
    text-align: center;
    color: $white;
    padding: 2rem;
    gap: 4.35rem;
    grid-template-areas:
      '.  .'
      'a  b'
      '.  .';
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 630px) {
      gap: 2rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        'a'
        'b';
    }
  }

  &__price {
    grid-area: a;
  }

  &__benefits {
    grid-area: b;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: hsla(0, 0%, 0%, 0.7);
    gap: 1rem;
    padding: 40px 26px;

    @include mobile {
      grid-row: auto;
      padding: 30px 15px;
    }

    &-title {
      @include footerTitleText();
      font-weight: 400;

      @include mobile {
        font-weight: 500;
      }
    }

    &-text {
      @include normalText();
      font-weight: 300;

      @include tablet {
        font-weight: 400;
      }
    }
  }
}