.dialogue-container {
  width: 375px;
  padding: 20px 20px 30px 20px;

  @include mobile() {
    width: 100%;
  }

  &__LEPartner {
    background-color: $lighterGold;
  }

  &__top-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    & button {
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    & svg {
      width: 6px;
      height: 12px;
    }
  }

  &__content {
    .dialogue-icon {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    .dialogue-title {
      @include titleText();
      color: $black;
      font-weight: bold;
      text-align: center;
      padding-bottom: 2rem;
    }

    .dialogue-inner-title {
      @include titleText();
      color: $black;
      font-weight: bold;
      text-align: center;
    }

    .dialogue-sub-title {
      @include dialogueTitleText();
      color: $gray;
      font-weight: bold;
      text-align: center;
    }

    .dialogue-fields {
      @include normalText();
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 25px;

      &__centered_row {
        text-align: center;
      }
    }

    .dialogue-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .dialogue-cards {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .dialogue-texts {
      @include normalText();
      color: $black;
    }

    .dialogue-payment-fields {
      border: 1px solid $lightGray;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      &__total {
        @include footerTitleText();
        color: $black;
        text-align: center;
      }

      &__price {
        @include dialogueTitleText();
        color: $black;
        text-align: center;
        font-weight: bold;
      }

      &__price-description {
        @include smallText();
        color: $gray;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
}
