@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNextNewW01Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNextW02Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/fonts/AvenirNextLTW02Demi.woff2');
  font-weight: 600;
}
