@mixin smallText {
  font-family: 'Avenir Next';
  font-size: 0.6875rem; // 11px
}

@mixin hotelCardDescText {
  font-family: 'Avenir Next';
  font-size: 0.75rem; // 12px
}

// p
@mixin normalText {
  font-family: 'Avenir Next';
  font-size: 0.875rem; // 14px
}

// H4
@mixin dialogueTitleText {
  font-family: 'Avenir next';
  font-size: 1.5rem; // 24px
}

// H5
@mixin footerTitleText {
  font-family: 'Avenir Next';
  font-size: 1.125rem; // 18px
}

// H3
@mixin titleText {
  font-family: 'Avenir Next';
  font-size: 1.75rem; // 28px
}

@mixin pageMobileTitleText {
  font-family: 'Avenir Next';
  font-size: 2rem; // 32px
}

// H1
@mixin pageTitleText {
  font-family: 'Avenir Next';
  font-size: 2.25rem; // 36px
}

// H1 H2
@mixin articleTitleText {
  font-family: 'Cormorant', serif;
  font-size: 3rem; // 48px
}
