.text-field-container {
  &__label {
    @include normalText();
    color: $black;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .date-selector-input {
      width: 100%;
      cursor: pointer;
    }
  }
}

.hide-header-year {
  & .MuiPickersCalendarHeader-label {
    margin-right: -74px;
  }

  & .MuiPickersCalendarHeader-labelContainer > button {
    visibility: hidden;
  }
}
