.membership-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &__image {
    display: flex;
    justify-content: center;
    &--left {
      justify-content: left;
    }
  }
  &__title {
    @include titleText();
    text-align: center;
    font-weight: bold;
    color: $black;
    margin-top: 1rem;
    &.left {
      text-align: left;
    }
  }
  &__price {
    margin-top: 1rem;
    text-align: center;
    &.left {
      text-align: left;
    }
    .price-value {
      @include footerTitleText();
      color: $gray;
      font-weight: 500;
    }
    .price-description {
      @include smallText();
      color: $gray;
    }
  }
  &__description {
    @include normalText();
    color: $gray;
    line-height: 19.5px;
    margin: 1rem 0;
  }
  &__benefits {
    .benefit-item {
      @include normalText();
      margin: 1rem 0;
      &__title {
        font-weight: bold;
        color: $black;
        margin-bottom: 0.4rem;
      }
      &__description {
        color: $gray;
      }
    }
  }
  &__bottom-actions {
    @include smallText();
    text-align: center;
    color: $gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
}
