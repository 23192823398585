.text-field-container {
  &__label {
    @include normalText();
    color: $black;
    font-weight: bold;
    margin-bottom: 0.4rem;
    line-height: 140%;
  }
  &__small-label {
    @include smallText();
    color: $black;
    margin-bottom: 0.2rem;
  }
  &__end-adornment-text {
    @include smallText();
    color: $black;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  & input {
    background-color: $white;
  }
}
