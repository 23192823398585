.top-picks-container {
  text-align: center;
  margin: 2rem auto;

  &__menu {
    max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem !important;
  }

  &__title {
    @include pageTitleText();
    font-weight: 600;
    text-align: center;
    color: $black;
  }

  &__current {
    @include hotelCardDescText();
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray;
    gap: 1rem;
  }

  &__button {
    width: auto;
    background-color: transparent;
    border: none;
  }

  .divider {
    width: 15rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding: 1rem 0;

    @include tablet() {
      padding: 1.5rem 0;
    }
  }
}
