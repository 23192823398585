.say-about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 61px;

  @include tabletSmall() {
    padding-top: 10px;
    margin-bottom: 50px;
    padding: 23px 20px 0;
  }

  &__title {
    font-size: 32px;
    color: $black;
    user-select: none;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include tabletSmall() {
      font-size: 24px;
    }
  }

  &__icon {
    justify-content: center;
    text-align: center;
    margin: 33px 0 29px;
    height: 74px;
    display: flex;
    align-items: center;

    @include tabletSmall() {
      margin: 0;
    }
  }

  &__slider {
    .slider__carousel {
      height: 290px;
    }

    justify-content: center;
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
    width: 90%;

    @include tabletSmall() {
      width: 100%;
      height: 220px;
    }

    .slider-container {
      max-width: 589px;
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tabletSmall() {
        width: 100%;
      }

      &__text {
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 1.37;
        color: $black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        @include tabletSmall() {
          font-size: 16px;
        }

        @include mobile() {
          font-size: 14px;
        }
      }

      &__line {
        width: 82px;
        height: 2px;
        background-color: $brandColor;
        margin: 20px 0;
      }

      &__member-and-destination {
        @include normalText();
        color: $gray;
        font-weight: 600;
      }

      &__membership-tier {
        @include smallText();
        text-transform: uppercase;
        color: $gray;
        margin-top: 10px;
        font-weight: 600;
      }
    }

    button:has(> .slider__carousel__arrow-left) {
      transform: translateY(-117px);
      transition: none;

      @include tabletSmall() {
        transform: translateY(-23px) translateX(10px);
      }

      @include mobile() {
        transform: translateY(0px) translateX(10px);
      }
    }

    button:has(> .slider__carousel__arrow-right) {
      transform: translateY(-117px);
      transition: none;

      @include tabletSmall() {
        transform: translateY(-23px) translateX(-10px);
      }

      @include mobile() {
        transform: translateY(0px) translateX(-10px);
      }
    }
  }
}