.myler-resources {
  position: relative;
  background-color: $lighterGold;
  padding-bottom: 40px;

  &__imageContainer {
    position: relative;
    width: 100%;
    height: 520px;
    flex-shrink: 0;
    background: url('../assets/myler-amanjena-morocco-cover.png') no-repeat;
    background-position: -451px;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  &__image {
    z-index: 0;
    background-size: 300%;
  }

  &__gradient {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      180deg,
      #6d4b24 0%,
      rgba(109, 75, 36, 0.7) 50%,
      rgba(109, 75, 36, 0.04) 100%
    );
  }

  &__heading {
    position: absolute;
    z-index: 2;
    margin-top: 68px;
    text-align: center;
    max-width: 350px;
  }

  &__cardList {
    position: relative;
    z-index: 3;
    margin-top: 40px;

    &-wrapper {
      @include containerWithMargin();
      height: 100%;
      display: flex;
      position: relative;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.resource-card {
  padding: 30px 15px;
  background-color: $white;
  max-width: 530px;
  border-radius: 10px;

  &__content {
    margin-top: 20px;
  }

  &__title,
  &__content {
    text-align: left;
  }

  &__content p:last-child {
    margin-top: 15px;
  }
}

@media screen and (min-width: $md) {
  .myler-resources {
    padding-bottom: 0px;

    &__imageContainer {
      background-position: 0px;
    }

    &__heading {
      margin-top: 115px;
      max-width: 570px;
    }

    &__cardList {
      top: -80px;
      margin-top: 0px;

      &-wrapper {
        justify-content: center;
        gap: 30px 36px;
      }
    }
  }

  .resource-card {
    padding: 30px;
  }
}
