.instagram-section-container {
  @include containerWithMargin();
  &__title {
    @include dialogueTitleText();
    color: $black;
    font-weight: bold;
    margin: 1.5rem 0;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    .mobile-view {
      color: $gray;
      &__bottom {
        display: flex;
        justify-content: center;
        .icon {
          padding-right: 0.5rem;
        }
      }
    }
  }
  &__description {
    @include footerTitleText();
    color: $gray;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'image-1 image-1 image-2 image-3'
      'image-1 image-1 image-4 image-5';
    row-gap: 1rem;
    column-gap: 1rem;
    padding-bottom: 80px;
    @include tablet() {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'image-1 image-1'
        'image-1 image-1'
        'image-2 image-3'
        'image-4 image-5';
    }
    @include mobile() {
      grid-template-columns: 1fr;
      grid-template-areas:
        'image-1'
        'image-2'
        'image-3';
      padding-bottom: 40px;
    }
    .insta-image-1 {
      grid-area: image-1;
    }
    .insta-image-2 {
      grid-area: image-2;
    }
    .insta-image-3 {
      grid-area: image-3;
    }
    .insta-image-4 {
      grid-area: image-4;
      @include mobile() {
        display: none;
      }
    }
    .insta-image-5 {
      grid-area: image-5;
      @include mobile() {
        display: none;
      }
    }

    & img {
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
